import gsap from "gsap";

export default class GScroll
{
	constructor (elmt, speed, onUpdate = () => true)
	{	
		this.speed = speed/10 || 0.06;
		this.elmt = elmt;
		this.isWheeling = null;
		this.deltaY = 0;
		this.update = onUpdate;

		// A VERIFIER
		this.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		this.isSafari = window.safari !== undefined;
	}

	init ()
	{
		this.current = this.scrollTop = 0;

		this.height = document.body.clientHeight-window.innerHeight;
		//this.deplacement = gsap.quickSetter(this.elmt, "y", "px");
		
		this.addTicker = () => {
	      this.playTicker();
	    }
	    gsap.ticker.add(this.addTicker);
	}

	wheel()
	{

		window.addEventListener('wheel', this.ref = (e) => {
			
			if(this.isFirefox || this.isSafari){
				this.deltaY = e.deltaY * 1.6;
			}else{
				this.deltaY = e.deltaY;	
			}
			
			window.clearTimeout( this.isWheeling );
            this.isWheeling = setTimeout( () => {
                this.deltaY = 0;
            }, 66);
            //console.log(this.deltaY);
        });
	}

	unwheel()
	{
		window.removeEventListener('wheel', this.ref);	
	}

	resize()
	{
		this.height = document.body.clientHeight-window.innerHeight;
	}

	scrollTo(section, dur)
	{
		const duration = dur || 1;
		gsap.to(this, {
			scrollTop: document.querySelector(section).getBoundingClientRect().top - this.current,
			duration,
			ease:'power3.inOut'
		})
	}

	// offTicker(){
	// 	gsap.ticker.remove(this.addTicker);
	// }
	// onTicker(){
	// 	gsap.ticker.add(this.addTicker);
	// }

	playTicker(){

		// var elapsed = (Date.now() - this.start);
  		// console.log(elapsed, gsap.ticker.deltaRatio())

		const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio());
		// const dt = 1.0 - Math.pow(1.0 - this.speed, elapsed/15);

		
		

		if(this.scrollTop + this.deltaY > this.height){
			this.scrollTop = this.height;
		}else if(this.scrollTop + this.deltaY < 0){
			this.scrollTop = 0;
		}else if(this.deltaY !== 0){
			this.scrollTop += this.deltaY;
		}

		const diff = -this.scrollTop - this.current;
        if(Math.round(100*diff)/100 != 0){
        	this.current += diff * dt;
        	//this.deplacement(this.current);
        	window.scrollTo(0, -1*this.current)
        }
		//console.log('oui')
		this.update();

  		

  		// this.start = Date.now();
    }

	destroy()
	{
		//gsap.killTweensOf(this.elmt);
		window.removeEventListener('wheel', this.ref);
		gsap.ticker.remove(this.addTicker);
	}
}