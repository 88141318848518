import gsap from "gsap";

export default class Nuage
{
	constructor ()
	{	

		this.deltaY = this.inten = 0;
		this.speed = 0.1;

		this.elmt = document.querySelector('.innerNuage1');
		this.posX = -0.3 * window.innerWidth;
		

		this.elmt2 = document.querySelector('.innerNuage2');
		this.posX2 = -0.7 * window.innerWidth;

		this.elmt3 = document.querySelector('.innerNuage3');
		this.posX3 = -0.5 * window.innerWidth;

		//this.init()
	}

	init ()
	{
		this.xSet = gsap.quickSetter(this.elmt, "x", "px");
		this.xSet2 = gsap.quickSetter(this.elmt2, "x", "px");
		this.xSet3 = gsap.quickSetter(this.elmt3, "x", "px");

		this.height = this.elmt.clientWidth

		this.addTicker = () => {
	      this.playTicker();
	    }

	    this.on();
	    
	}

	on() {
		gsap.ticker.add(this.addTicker);
		// window.addEventListener('wheel', this.ref = (e) => {
		// 	this.deltaY = e.deltaY/2;
		// 	window.clearTimeout( this.isWheeling );
  //           this.isWheeling = setTimeout( (e) => {
  //               this.deltaY = 0;
  //           }, 66);
  //       });
	}

	playTicker(){
		const gDt = gsap.ticker.deltaRatio()
		const dt = 1.0 - Math.pow(1.0 - this.speed, gDt);

		this.xSet(this.posX);
		this.xSet2(this.posX2);
		this.xSet3(this.posX3);

		this.inten += (this.deltaY/4 - this.inten) * dt;
		
		this.posX -= (gDt + this.inten) / 1.3
		this.posX2 -= (gDt + this.inten) * 1.3
		this.posX3 -= (gDt + this.inten)


		if(this.posX > 0) {
			this.posX = - this.height;
        }
        if(this.posX < -(this.height)) {
			this.posX = this.posX + this.height;
        }

        if(this.posX2 > 0) {
			this.posX2 = - this.height;
        }
        if(this.posX2 < -(this.height)) {
			this.posX2 = this.posX2 + this.height;
        }
        if(this.posX3 > 0) {
			this.posX3 = - this.height;
        }
        if(this.posX3 < -(this.height)) {
			this.posX3 = this.posX3 + this.height;
        }
	}

	destroy()
	{
		window.removeEventListener('wheel', this.ref);
		// gsap.ticker.remove(this.addTicker);
	}
}