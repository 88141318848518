// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import GScroll from "modules/GScroll"
import Nuage from "modules/Nuage"

import {isTouch} from "modules/utils"

gsap.registerPlugin(ScrollTrigger)

let interval;
window.addEventListener("DOMContentLoaded", () => {

    const nuage = new Nuage();

    let scroll;
    if(!isTouch()){
        scroll = new GScroll('body', 0.4);
        window.scrollTo(0, 0);
    }

    window.pageYOffset = 0;

    let scrollDejaApparu = false, introOver = false;

    let tlGre = gsap.timeline({paused:true});
    let tlHib = gsap.timeline({paused:true});

    gsap.delayedCall(1, () => {

        document.getElementById('main').classList.add('on')

        // let interval = setInterval(() => {
        //     const el = document.querySelector('.personnage img.current')
        //     el.classList.remove('current')

        //     if(el.nextElementSibling){
        //         el.nextElementSibling.classList.add('current')
        //     }else{
        //         document.querySelectorAll('.personnage img')[0].classList.add('current')
        //     }
        // }, 220)

        gsap.delayedCall(0.3, () => {

            gsap.fromTo('.logo', {
                rotation:'4deg',
                scale:0.8
            },{
                rotation:'0deg',
                scale:1,
                duration:2,ease:'power4.out'
            })

            if(window.innerWidth > 767){
                gsap.fromTo(".stag", {
                    y:'-80%',
                    scaleY:0.5,
                },{
                    y:'0%',
                    scaleY:1,
                    ease:"elastic.out(1.4, 1.)",
                    stagger:-0.11,
                    duration:1.2
                })

                gsap.fromTo(".stag2", {
                    y:'80%',
                    scaleY:0.5
                },{
                    y:'0%',
                    scaleY:1,
                    ease:"elastic.out(1.4, 1.)",
                    stagger:0.11,
                    duration:1.2
                })
            }else{
                gsap.fromTo(".stag", {
                    autoAlpha:0,
                    y:'-80%',
                    scaleY:0.5,
                },{
                    autoAlpha:1,
                    y:'0%',
                    scaleY:1,
                    ease:"elastic.out(1.4, 1.)",
                    stagger:-0.11,
                    duration:1.2
                })

                gsap.fromTo(".stag2", {
                    autoAlpha:0,
                    y:'80%',
                    scaleY:0.5
                },{
                    autoAlpha:1,
                    y:'0%',
                    scaleY:1,
                    ease:"elastic.out(1.4, 1.)",
                    stagger:0.11,
                    duration:1.2
                })
            }

            gsap.to(".motHaut, .motBas", {
                y:0,
                ease:"power4.out",
                duration:1.9,
                onComplete:() => {
                    
                    introOver = true

                    if(scroll != undefined){
                        scroll.init();
                        scroll.wheel();
                    }
                }
            })

            if(window.innerWidth <= 767){
                gsap.delayedCall(1.3, () => {
                    document.getElementById('parchemin').classList.add('on')
                })
                
                gsap.fromTo('.personnage', {
                    autoAlpha:0,
                },{
                    autoAlpha:1,
                    y:0,
                    ease:'power3.out',
                    delay:1.3,
                    duration:0.6
                })
            }
        })

        gsap.delayedCall(1.1, () => {
            gsap.to('.innerNuage', {
                y:'0px',
                ease:"elastic.out(1.4, 1.)",
                stagger:0.1,
                duration:2
            })
            nuage.init();
        })

        if(window.innerWidth > 767){
            allTriggers()
        }else{
            ScrollTrigger.create({
                trigger:'#main',
                start:'top+=' + (document.querySelector('.s-personnage').getBoundingClientRect().top - window.innerHeight) + 'px',
                end:'+=' + (window.innerHeight + document.querySelector('.personnage').offsetHeight) + 'px',
                onEnter:() => {playInter()},
                onEnterBack:() => {playInter()},
                onLeave:() => {killInter()},
                onLeaveBack:() => {killInter()}
            })

            ScrollTrigger.create({
                trigger:'#main',
                start:'top+=' + (document.querySelector('footer').getBoundingClientRect().top - window.innerHeight) + 'px',
                onEnter:() => {
                    document.getElementById('parchemin').classList.remove('on')
                },
                onLeaveBack:() => {
                    document.getElementById('parchemin').classList.add('on')
                }
            })
        }

        

        // pastille
        gsap.set("#scrollV", {xPercent: -50, yPercent: -50, x:'50vw',y:'50vh'});
        let xTo = gsap.quickTo("#scrollV", "x", {duration: 0.5, ease: "power4"}),
        yTo = gsap.quickTo("#scrollV", "y", {duration: 0.5, ease: "power4"});

        gsap.set("#scrollMot", {xPercent: -50, yPercent: -50, x:'50vw',y:'50vh'});
        let xTo2 = gsap.quickTo("#scrollMot", "x", {duration: 0.6, ease: "power4"}),
        yTo2 = gsap.quickTo("#scrollMot", "y", {duration: 0.6, ease: "power4"});

        window.addEventListener("mousemove", e => {
            xTo(e.clientX);
            yTo(e.clientY - 40);

            xTo2(e.clientX);
            yTo2(e.clientY - 40);

            if(!scrollDejaApparu && document.getElementById('main').getBoundingClientRect().top == 0 && introOver){
                scrollDejaApparu = true
                gsap.fromTo('#scrollV, #scrollMot', {
                    rotation:-90
                },{
                    scale:1,
                    rotation:0,
                    duration:0.5,
                    ease:'power2.inOut'
                })
            }
        });

        document.addEventListener('scroll', () => {
            scrollDejaApparu = true;
        }, {once : true})

        

        if(window.innerWidth > 767){
            gsap.set('.corps', {y:'54%'})
            gsap.set('.bras', {y:'76%', x:'-1%'})
            gsap.set('.main', {scaleY:0})

            
            tlGre.to('.corps, .bras', {
                y:'0%',
                ease:"power4.out",
                duration:0.5
            })
            tlGre.to('.main', {
                scaleY:1,
                duration:0.2,
                ease:"power4.out"
            }, '-=0.4')

            document.getElementById('grenouille').addEventListener('mouseenter', () => {
                tlGre.reverse()
            })
            document.getElementById('grenouille').addEventListener('mouseleave', () => {
                tlGre.play()
            })

            document.getElementById('parchemin').addEventListener('mouseenter', () => {
                document.getElementById('onSecrit').classList.add('on')
            })
            document.getElementById('parchemin').addEventListener('mouseleave', () => {
                document.getElementById('onSecrit').classList.remove('on')
            })
        }



        // hibou
        // hibou
        // hibou
        if(window.innerWidth > 767){
            gsap.set('#oeilGauche, #oeilDroit', {
                scaleY:0,
                transformOrigin:"0 70%"
            })
            
            tlHib.set('#yeuxFermes', {autoAlpha:0})
            tlHib.set('#oeilGauche, #oeilDroit', {autoAlpha:1})
            tlHib.to('#oeilGauche, #oeilDroit', {
                scaleY:1,
                duration:0.2,
                ease:"power4.out"
            }, '+=0.2')

            document.getElementById('hibou').addEventListener('mouseenter', () => {
                tlHib.play()
            })
            document.getElementById('hibou').addEventListener('mouseleave', () => {
                tlHib.reverse()
            })
        }


        // click
        document.getElementById('parchemin').addEventListener('click', () => {
            if(window.innerWidth > 767){
                document.getElementById('parchemin').classList.remove('on')
                document.getElementById('onSecrit').classList.remove('on')
            }

            let progression = (document.getElementById('main').getBoundingClientRect().top * -100 / (document.getElementById('main').offsetHeight - window.innerHeight))/100
            if(progression > 0.8){
                progression = 0.8
            }

            const progress = {dist:document.getElementById('main').getBoundingClientRect().top * -1}
            let distance = document.getElementById('main').offsetHeight - window.innerHeight;
            if(window.innerWidth <= 767){
                distance = document.getElementById('main').offsetHeight - document.querySelector('footer').offsetHeight;
            }
            gsap.to(progress, {
                dist: distance,
                duration:4 * (1 - progression),
                ease:'power2.inOut',
                onUpdate: () => {
                    window.scrollTo(0, progress.dist);

                    if(scroll != undefined){
                        scroll.scrollTop = progress.dist;
                        scroll.current = -progress.dist; // IMPORTANT
                    }
                }
            })
        })
    })

    function allTriggers() {
        ScrollTrigger.create({
            trigger:'#main',
            start:'top+=' + (document.querySelector('.logo').getBoundingClientRect().left + document.querySelector('.logo').clientWidth) + 'px',
            onEnter:() => {
                document.querySelector('.innerNuage2').classList.add('derriere')
            },
            onLeaveBack:() => {
                document.querySelector('.innerNuage2').classList.remove('derriere')
            }
        })

        gsap.to('.skew', {
            skewX: '-80deg',
            ease:'none',
            scrollTrigger:{
                trigger:'#main',
                start:'top top',
                end:'+=' + window.innerWidth,
                scrub:true
            }
        })

        ScrollTrigger.create({
            trigger:'#main',
            start:'top top',
            onEnter:() => {
                gsap.to('#scrollV, #scrollMot', {
                    scale:0,
                    rotation:90,
                    duration:0.5,
                    ease:'power2.inOut'
                })

                document.querySelector('.step1').classList.add('off')
                document.querySelector('.step2').classList.add('on')

                document.getElementById('parchemin').classList.add('on')
            },
            onLeaveBack:() => {
                if(scrollDejaApparu){
                    gsap.fromTo('#scrollV, #scrollMot', {
                        rotation:-90
                    },{
                        scale:1,
                        rotation:0,
                        duration:0.5,
                        ease:'power2.inOut'
                    })
                }

                document.querySelector('.step1').classList.remove('off')
                document.querySelector('.step2').classList.remove('on')

                document.getElementById('parchemin').classList.remove('on')
                document.getElementById('onSecrit').classList.remove('on')
            }
        })


        // scroll horizontal
        gsap.to('#scroll', {
            x:- 1 * document.querySelector('#scroll').clientWidth + window.innerWidth + 'px',
            ease:"none",
            scrollTrigger:{
                trigger: '#scroll',
                start: 'top top',
                end: '+=' + (document.querySelector('#scroll').clientWidth - window.innerWidth) + 'px',
                scrub: true,
                pin: true
            }
        })

        ScrollTrigger.create({
            trigger:'#main',
            start:'top+=' + (document.querySelector('.personnage').getBoundingClientRect().left - window.innerWidth) + 'px',
            end:'+=' + (window.innerWidth + document.querySelector('.personnage').offsetWidth) + 'px',
            onEnter:() => {playInter()},
            onEnterBack:() => {playInter()},
            onLeave:() => {killInter()},
            onLeaveBack:() => {killInter()}
        })

        gsap.to('#lampe, .bibli',{
            x:window.innerWidth + 'px',
            ease:'none',
            scrollTrigger:{
                trigger:'#lampe',
                start:'top+=' + (document.getElementById('lampe').getBoundingClientRect().left - 0.5*window.innerWidth + document.getElementById('lampe').offsetWidth/2) + 'px',
                end:'+=' + window.innerWidth + 'px',
                scrub: true
            }
        })
        gsap.to('.escalier, .rocher1',{
            x:'0px',
            ease:'none',
            scrollTrigger:{
                trigger:'#lampe',
                start:'top+=' + (document.getElementById('lampe').getBoundingClientRect().left - 0.5*window.innerWidth + document.getElementById('lampe').offsetWidth/2) + 'px',
                end:'+=' + window.innerWidth + 'px',
                scrub: true
            }
        })
        gsap.to('.lumiere',{
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            ease:'none',
            scrollTrigger:{
                trigger:'#lampe',
                start:'top+=' + (document.getElementById('lampe').getBoundingClientRect().left - 0.5*window.innerWidth + document.getElementById('lampe').offsetWidth/2) + 'px',
                end:'+=' + window.innerWidth + 'px',
                scrub: true
            }
        })


        ScrollTrigger.create({
            trigger:'#main',
            start:'top+=' + (document.querySelector('.s-frise').getBoundingClientRect().left) + 'px',
            onEnter:() => {
                nuage.destroy()
            },
            onLeaveBack:() => {
                nuage.on()
            }
        })

        
        document.querySelectorAll('.s-frise img').forEach(el => {
            
            gsap.from(el, {
                immediateRender: false,
                rotation: (Math.random() - 0.5) * 40,
                y: ((Math.random() - 0.5) * 100) + 'px',
                x: 0.1*window.innerWidth+'px',
                ease:'power2.out',
                scrollTrigger:{
                    trigger:'#main',
                    start:'top+=' + (el.getBoundingClientRect().left - window.innerWidth) + 'px',
                    end:'+=' + (0.25 * window.innerWidth) + 'px',
                    scrub:true
                }
            })

            gsap.to(el, {
                immediateRender:false,
                rotation: (Math.random() - 0.5) * 40,
                y: ((Math.random() - 0.5) * 100) + 'px',
                x: -0.1*window.innerWidth+'px',
                ease:'power2.in',
                scrollTrigger:{
                    trigger:'#main',
                    start:'top+=' + (el.getBoundingClientRect().left + el.clientWidth - 0.25 * window.innerWidth) + 'px',
                    end:'+=' + (0.25 * window.innerWidth) + 'px',
                    scrub:true
                }
            })
        })
 
        // footer
        gsap.to('.forme', {
            x:'100%',
            ease:'none',
            scrollTrigger: {
                trigger:'#main',
                start:'top+=' + (document.querySelector('.s-footer').getBoundingClientRect().left - 0.5 * window.innerWidth) + 'px',
                end:'+=' + (0.5 * window.innerWidth) + 'px',
                scrub:true,
                onEnter:() => {
                    document.body.classList.add('noir')
                },
                onLeaveBack:() => {
                    document.body.classList.remove('noir')
                }
            }
        })



        ScrollTrigger.create({
            trigger:'#main',
            start:'top+=' + (document.querySelector('footer').getBoundingClientRect().left - 0.1 * window.innerWidth) + 'px',
            onEnter:() => {
                tlGre.play()
            },
            onLeaveBack:() => {
                tlGre.reverse()
            }
        })

        ScrollTrigger.create({
            trigger:'#main',
            start:'top+=' + (document.querySelector('footer').getBoundingClientRect().left - 50) + 'px',
            onEnter:() => {
                document.querySelector('.credits').classList.add('on')
            },
            onLeaveBack:() => {
                document.querySelector('.credits').classList.remove('on')
            }
        })

        ScrollTrigger.create({
            trigger:'#main',
            start:'top+=' + (document.querySelector('footer').getBoundingClientRect().left - window.innerWidth) + 'px',
            onEnter:() => {
                document.getElementById('parchemin').classList.remove('on')
                document.getElementById('onSecrit').classList.remove('on')
            },
            onLeaveBack:() => {
                document.getElementById('parchemin').classList.add('on')
            }
        })

        
    }

    // A CONTINUER
    window.addEventListener('resize', () => {
        if(scroll != undefined){
            location.reload()
        }
    });
    

})

function playInter() {
    interval = setInterval(() => {
        const el = document.querySelector('.personnage img.current')
        el.classList.remove('current')

        if(el.nextElementSibling){
            el.nextElementSibling.classList.add('current')
        }else{
            document.querySelectorAll('.personnage img')[0].classList.add('current')
        }
    }, 150)
}
function killInter() {
    clearInterval(interval)
}

